@import url(https://use.fontawesome.com/releases/v5.3.1/css/all.css);
@import url(https://fonts.googleapis.com/css?family=Krub:400,700);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box; }

html,
body {
  width: 100%;
  height: 100%; }

body {
  background: #202020;
  font-family: "Krub", sans-serif; }

.card {
  position: absolute;
  margin: auto;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 250px;
  height: 400px;
  border-radius: 10px;
  box-shadow: 0 10px 25px 5px rgba(0, 0, 0, 0.2);
  background: #151515;
  overflow: hidden; }
  .card .ds-top {
    position: absolute;
    margin: auto;
    top: 0;
    right: 0;
    left: 0;
    width: 300px;
    height: 80px;
    background: #7f00ff;
    /* fallback for old browsers */
    /* Chrome 10-25, Safari 5.1-6 */
    background: -webkit-gradient(linear, right top, left top, from(#e100ff), to(#7f00ff));
    background: linear-gradient(to left, #e100ff, #7f00ff);
    /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
    -webkit-animation: dsTop 1.5s;
            animation: dsTop 1.5s; }
  .card .avatar-holder {
    position: absolute;
    margin: auto;
    top: 40px;
    right: 0;
    left: 0;
    width: 100px;
    height: 100px;
    border-radius: 50%;
    box-shadow: 0 0 0 5px #151515, inset 0 0 0 5px #000000, inset 0 0 0 5px #000000, inset 0 0 0 5px #000000, inset 0 0 0 5px #000000;
    background: white;
    overflow: hidden;
    -webkit-animation: mvTop 1.5s;
            animation: mvTop 1.5s; }
    .card .avatar-holder img {
      width: 100%;
      height: 100%;
      object-fit: cover; }
  .card .name {
    position: absolute;
    margin: auto;
    top: -60px;
    right: 0;
    bottom: 0;
    left: 0;
    width: inherit;
    height: 40px;
    text-align: center;
    -webkit-animation: fadeIn 2s ease-in;
            animation: fadeIn 2s ease-in; }
    .card .name a {
      color: white;
      text-decoration: none;
      font-weight: 700;
      font-size: 18px; }
      .card .name a:hover {
        color: #7f00ff; }
    .card .name h6 {
      position: absolute;
      margin: auto;
      left: 0;
      right: 0;
      bottom: 0;
      color: white;
      width: auto; }
  .card .button {
    position: absolute;
    margin: auto;
    padding: 8px;
    top: 20px;
    right: 0;
    bottom: 0;
    left: 0;
    width: inherit;
    height: 40px;
    text-align: center;
    -webkit-animation: fadeIn 2s ease-in;
            animation: fadeIn 2s ease-in;
    outline: none; }
    .card .button a {
      padding: 5px 20px;
      border-radius: 10px;
      color: white;
      letter-spacing: 0.05em;
      text-decoration: none;
      font-size: 10px;
      -webkit-transition: all 1s;
      transition: all 1s; }
      .card .button a:hover {
        color: white;
        background: #7f00ff;
        /* fallback for old browsers */
        /* Chrome 10-25, Safari 5.1-6 */
        background: -webkit-gradient(linear, right top, left top, from(#e100ff), to(#7f00ff));
        background: linear-gradient(to left, #e100ff, #7f00ff);
        /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */ }
  .card .ds-info {
    position: absolute;
    margin: auto;
    top: 120px;
    bottom: 0;
    right: 0;
    left: 0;
    width: inherit;
    height: 40px;
    display: flex; }
    .card .ds-info .pens,
    .card .ds-info .projects,
    .card .ds-info .posts {
      position: relative;
      left: -300px;
      width: calc(250px / 3);
      text-align: center;
      color: white;
      -webkit-animation: fadeInMove 2s;
              animation: fadeInMove 2s;
      -webkit-animation-fill-mode: forwards;
              animation-fill-mode: forwards; }
      .card .ds-info .pens h6, .card .ds-info .pens a,
      .card .ds-info .projects h6,
      .card .ds-info .projects a,
      .card .ds-info .posts h6,
      .card .ds-info .posts a {
        text-transform: uppercase;
        color: #7f00ff; }
      .card .ds-info .pens p,
      .card .ds-info .projects p,
      .card .ds-info .posts p {
        font-size: 12px; }
    .card .ds-info .ds:nth-of-type(2) {
      -webkit-animation-delay: 0.5s;
              animation-delay: 0.5s; }
    .card .ds-info .ds:nth-of-type(1) {
      -webkit-animation-delay: 1s;
              animation-delay: 1s; }
  .card .ds-skill {
    position: absolute;
    margin: auto;
    bottom: 10px;
    right: 0;
    left: 0;
    width: 200px;
    height: 100px;
    -webkit-animation: mvBottom 1.5s;
            animation: mvBottom 1.5s; }
    .card .ds-skill h6 {
      margin-bottom: 5px;
      font-weight: 700;
      text-transform: uppercase;
      color: #7f00ff; }
    .card .ds-skill .skill h6 {
      font-weight: 400;
      font-size: 8px;
      letter-spacing: 0.05em;
      margin: 4px 0;
      color: white; }
    .card .ds-skill .skill .fab {
      color: #7f00ff;
      font-size: 14px; }
    .card .ds-skill .skill .bar {
      height: 5px;
      background: #7f00ff;
      text-align: right; }
      .card .ds-skill .skill .bar p {
        color: white;
        font-size: 8px;
        padding-top: 5px;
        -webkit-animation: fadeIn 5s;
                animation: fadeIn 5s; }
      .card .ds-skill .skill .bar:hover {
        background: white; }
    .card .ds-skill .skill .bar-html {
      width: 99%;
      -webkit-animation: htmlSkill 1s;
              animation: htmlSkill 1s;
      -webkit-animation-delay: 0.4s;
              animation-delay: 0.4s; }
    .card .ds-skill .skill .bar-css {
      width: 99%;
      -webkit-animation: cssSkill 2s;
              animation: cssSkill 2s;
      -webkit-animation-delay: 0.4s;
              animation-delay: 0.4s; }
    .card .ds-skill .skill .bar-js {
      width: 75%;
      -webkit-animation: jsSkill 3s;
              animation: jsSkill 3s;
      -webkit-animation-delay: 0.4s;
              animation-delay: 0.4s; }

@-webkit-keyframes fadeInMove {
  0% {
    opacity: 0;
    left: -300px; }
  100% {
    opacity: 1;
    left: 0; } }

@keyframes fadeInMove {
  0% {
    opacity: 0;
    left: -300px; }
  100% {
    opacity: 1;
    left: 0; } }

@-webkit-keyframes fadeIn {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@keyframes fadeIn {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@-webkit-keyframes htmlSkill {
  0% {
    width: 0; }
  100% {
    width: 99%; } }

@keyframes htmlSkill {
  0% {
    width: 0; }
  100% {
    width: 99%; } }

@-webkit-keyframes cssSkill {
  0% {
    width: 0; }
  100% {
    width: 99%; } }

@keyframes cssSkill {
  0% {
    width: 0; }
  100% {
    width: 99%; } }

@-webkit-keyframes jsSkill {
  0% {
    width: 0; }
  100% {
    width: 75%; } }

@keyframes jsSkill {
  0% {
    width: 0; }
  100% {
    width: 75%; } }

@-webkit-keyframes mvBottom {
  0% {
    bottom: -150px; }
  100% {
    bottom: 10px; } }

@keyframes mvBottom {
  0% {
    bottom: -150px; }
  100% {
    bottom: 10px; } }

@-webkit-keyframes mvTop {
  0% {
    top: -150px; }
  100% {
    top: 40px; } }

@keyframes mvTop {
  0% {
    top: -150px; }
  100% {
    top: 40px; } }

@-webkit-keyframes dsTop {
  0% {
    top: -150px; }
  100% {
    top: 0; } }

@keyframes dsTop {
  0% {
    top: -150px; }
  100% {
    top: 0; } }

.following {
  color: white;
  background: crimson; }

